import React from 'react';
import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { globalStyles } from '../../styles/variables';

const emptyCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="tests-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
  >
    <path
      fill={globalStyles.colors.primary}
      d="M512 170.666667a341.333333 341.333333 0 1 1-341.333333 341.333333 341.333333 341.333333 0 0 1 341.333333-341.333333m0-85.333334a426.666667 426.666667 0 1 0 426.666667 426.666667A426.666667 426.666667 0 0 0 512 85.333333z"
    />
  </svg>
);
const checkedCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="tests-icon"
    viewBox="0 0 1024 1024"
    version="1.1"
  >
    <path
      fill={globalStyles.colors.primary}
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m193.5 301.7l-210.6 292c-12.7 17.7-39 17.7-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"
    />
  </svg>
);

export const CheckedCircleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={checkedCircleIcon} {...props} />
);
export const EmptyCircleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={emptyCircleIcon} {...props} />
);
