import React, { FC, useContext } from 'react';
import { Typography, Row, Col, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { CheckedCircleIcon, EmptyCircleIcon } from './Icons';
const { Title } = Typography;

import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { StrapiChapter } from '../../types/index';

import './LessonsSection.less';
import { navigate } from 'gatsby';

type LessonsSectionType = {
  data: StrapiChapter;
};

const LessonsSection: FC<LessonsSectionType> = ({ data }) => {
  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);

  const renderLessons = () => {
    const lessonsList = data.lessons
      .sort((a, b) => a.lessonNumber - b.lessonNumber)
      .map(lesson => {
        const isTestSolved = true; //TODO
        const isActive = lesson.lessonNumber === 1 || authenticated;
        return (
          <>
            <Row className="lesson-items" key={lesson.strapi_id}>
              <Button
                className={`lesson-name ${isActive ? 'active' : null}`}
                onClick={() => navigate(`lekcja${lesson.lessonNumber}`)}
                disabled={isActive ? false : true}
              >
                <div className="lesson-name-container">
                  {lesson.lessonNumber}. {lesson.name}
                </div>
                {!isActive && <LockOutlined className="lock-icon" />}
              </Button>

              <Col className="lesson-test">
                {isActive ? (
                  <CheckedCircleIcon />
                ) : (
                  <EmptyCircleIcon className="inactive" />
                )}
              </Col>
            </Row>
          </>
        );
      });

    return (
      <>
        <Row className="lesson-items">
          <Col>
            <Title level={3}>Lekcje</Title>
          </Col>

          <Col>
            <Title level={3}>Test</Title>
          </Col>
        </Row>
        {lessonsList}
      </>
    );
  };

  return (
    <>
      <section className="lessons-section">
        <div className="lessons-container">{renderLessons()}</div>
      </section>
    </>
  );
};

export default LessonsSection;
