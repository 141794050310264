import React, { PureComponent } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Layout } from '../components/Layout/Layout';
import { StrapiChapter, StrapiPurchasedContentType } from '../types/index';
import ChapterOverview from '../components/ChapterOverview/ChapterOverview';
import LessonsSection from '../components/LessonsSection/LessonsSection';
import { Breadcrumb, Divider } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ROUTES } from '../constants/routes';

type ChapterPageProps = {
  strapiCourse: StrapiChapter;
  allStrapiPurchasedContent: {
    nodes: StrapiPurchasedContentType[];
  };
};

export default function ChapterPage({ data }: PageProps<ChapterPageProps>) {
  return (
    <Layout>
      <section className="section-content-container">
        <Breadcrumb separator={<ArrowRightOutlined />}>
          <Breadcrumb.Item href={ROUTES.HOME}>Strona główna</Breadcrumb.Item>
          <Breadcrumb.Item href={ROUTES.COURSE}>Kurs</Breadcrumb.Item>
          <Breadcrumb.Item href={`${ROUTES.COURSE}/${data.strapiCourse.slug}`}>
            {data.strapiCourse.name}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Divider></Divider>
      </section>
      <ChapterOverview
        data={data.strapiCourse}
        purchasedContent={data.allStrapiPurchasedContent.nodes}
      />
      <LessonsSection data={data.strapiCourse} />
    </Layout>
  );
}

export const query = graphql`
  query AllCoursesQuery($slug: String) {
    strapiCourse(slug: { eq: $slug }) {
      slug
      strapi_id
      id
      name
      stripeId
      description
      picture {
        localFile {
          url
        }
      }
      price
      lessons {
        name
        lessonNumber
        strapi_id
      }
      themeColor
    }
    allStrapiPurchasedContent {
      nodes {
        courses {
          id
        }
        owner {
          strapi_id
        }
      }
    }
  }
`;
