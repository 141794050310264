import { Button, Modal } from 'antd';
import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import { ROUTES } from '../../constants/routes';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import './AddToCartButton.less';

interface AddToCartButtonProps {
  id: number;
  stripeId: string;
  name: string;
  isActive: boolean;
}

const AddToCartButton = (props: AddToCartButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { increaseCartQuantity } = useShoppingCart();

  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);

  const handleOk = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(ROUTES.SHOPPING_CART);
  };

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsModalOpen(false);
  };

  const addToCart = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    increaseCartQuantity(props.id, props.stripeId);
    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        type="primary"
        className="add-btn"
        onClick={addToCart}
        disabled={props.isActive ? false : true}
      >
        Dodaj do koszyka
      </Button>
      <Modal
        title="Koszyk"
        visible={isModalOpen}
        onOk={handleOk}
        okText={authenticated && 'Przejdź do koszyka'}
        onCancel={handleCancel}
        cancelText="Zamknij"
        cancelButtonProps={{ type: 'ghost', className: 'cancel-modal-btn' }}
      >
        <p>
          Przedmiot: <strong>{props.name}</strong> został dodany do koszyka.
        </p>
      </Modal>
    </>
  );
};

export default AddToCartButton;
