import { Image, Typography } from 'antd';
import React, { FC, useContext } from 'react';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
const { Title, Text } = Typography;

import { StrapiChapter, StrapiPurchasedContentType } from '../../types/index';

import { globalStyles } from '../../styles/variables';
import AddToCartButton from '../Cart/AddToCartButton';
import './ChapterOverview.less';

type ChapterOverviewProps = {
  data: StrapiChapter;
  purchasedContent: StrapiPurchasedContentType[];
};

const ChapterOverview: FC<ChapterOverviewProps> = ({
  data,
  purchasedContent,
}) => {
  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);
  const userId =
    typeof window !== 'undefined' && localStorage?.getItem('userid');

  const purchasedCoursesByCurrentUser = userId
    ? purchasedContent
        .filter(course => course.owner.strapi_id === parseInt(userId))
        .map(data => data.courses.map(course => course.id))
        .reduce((accumulator, value) => accumulator.concat(value), [])
    : [];

  const isCurrentCoursePurchased = !!purchasedCoursesByCurrentUser.find(
    id => id === data.id
  );

  return (
    <div className="chapter-overview">
      <section className="hero-section">
        <div className="hero-section-main">
          <Title level={3} className="chapter-title">
            {data.name}
          </Title>
          <Text className="chapter-description">{data.description}</Text>
          {(!authenticated || (authenticated && !isCurrentCoursePurchased)) && (
            <>
              <Title level={3} className="price">
                {data.price} zł
              </Title>
              <AddToCartButton
                name={data.name}
                isActive={true}
                id={data.id}
                stripeId={data.stripeId}
              />
            </>
          )}
        </div>
        <div
          className="hero-section-theme"
          style={{ backgroundColor: `${globalStyles.colors[data.themeColor]}` }}
        >
          <Image src={data?.picture?.localFile?.url} alt="" preview={false} />
        </div>
      </section>
    </div>
  );
};

export default ChapterOverview;
